<template>
  <div class="dealer-page">
    <header class="page-header">
      <PageTitle v-if="dealer" :item="dealer" property="name"/>
      <div class="buttons">
        <button class="btn btn-save" @click="$formulate.submit('dealerForm')" v-if="!isLoading">{{ $t('Dealer opslaan') }}</button>
      </div>
    </header>

    <div class="main-content">
      <formulate-form v-if="!isLoading" ref="dealerForm" name="dealerForm" v-model="dealer" @submit="saveDealer">
        <tabs :options="{ useUrlFragment: false }">
          <tab name="Content">
            <formulate-input validation="required" error-behavior="submit" type="text" name="name" label="Naam"></formulate-input>

            <formulate-input type="group" name="address" :wrapper-class="['address']">
              <formulate-input validation="required" type="text" name="address1" label="Adres 1"></formulate-input>
              <formulate-input type="text" name="address2" label="Adres 2"></formulate-input>
              <formulate-input validation="required" type="text" name="postal_code" label="Postcode"></formulate-input>
              <formulate-input validation="required" type="text" name="locality" label="Gemeente"></formulate-input>
              <formulate-input validation="required" type="select" :options="countries" name="country_code" label="Land"></formulate-input>
            </formulate-input>

            <formulate-input validation="required|number" type="text" name="client_number" label="Klantnummer" :wrapper-class="['md']"></formulate-input>
            <formulate-input validation="required|email" type="email" name="email" label="E-mail" :wrapper-class="['lg']"></formulate-input>
            <formulate-input validation="required" type="text" name="phone" label="Telefoonnummer" :wrapper-class="['md']"></formulate-input>
            <formulate-input validation="required" type="text" name="website" label="Website"></formulate-input>
            <formulate-input validation="required" type="number" name="b2b_order_budget" label="Wilms marketingbudget"></formulate-input>

            <div class="formulate-input">
              <label class="formulate-input-label">Gebruikers</label>
              <v-select multiple name="users" :options="users" @search="searchUsers" id="dealerSelector" label="name" :filterable="false" v-model="chosenUsers">
                <div slot="list-footer" class="pagination">
                  <div @click="prevUsers()" :disabled="!hasPrevPage">Prev</div>
                  <div @click="nextUsers()" :disabled="!hasNextPage">Next</div>
                </div>
              </v-select>
            </div>
          </tab>

          <tab name="Loyalty">
            <formulate-input name="project_dealer" type="checkbox" label="Projectdealer"></formulate-input>
            <formulate-input type="group" name="loyalty">
              <h3>Kwaliteit</h3>
              <div class="grid">
                <formulate-input name="quality_payment_behavior_score" type="number" label="Betaalgedrag"></formulate-input>
                <formulate-input name="quality_followed_products_score" type="number" label="Gevolgde producten"></formulate-input>
                <formulate-input name="quality_training_staff_score" type="number" label="Kwaliteit & Training Personeel"></formulate-input>
                <formulate-input name="quality_payment_method_score" type="number" label="Bestelwijze"></formulate-input>
              </div>

              <h3>Sales & marketing</h3>
              <div class="grid">
                <formulate-input name="sales_marketing_followup_leads_score" type="number" label="Opvolging leads"></formulate-input>
                <formulate-input name="sales_marketing_visibility_score" type="number" label="Marketing & zichtbaarheid"></formulate-input>
              </div>

              <h3>Volume</h3>
              <div class="grid">
                <formulate-input name="volume_revenue_score" type="number" label="Omzet"></formulate-input>
                <formulate-input name="volume_seniority_score" type="number" label="Duurzaamheid & Anciënniteit"></formulate-input>
                <formulate-input name="volume_growth_score" type="number" label="Potentieel & groei"></formulate-input>
              </div>

              <h3>Omzet</h3>
              <formulate-input type="group" name="volume_revenue" :repeatable="true" remove-label="X" add-label="+ Omzet toevoegen">
                <formulate-input name="amount" type="number" label="Omzet"></formulate-input>
                <formulate-input name="year" type="number" label="Jaar"></formulate-input>
                <template v-slot:remove=""></template>
                <template v-slot:addmore=""></template>
              </formulate-input>
            </formulate-input>
          </tab>

          <tab name="Wilms website">
            <formulate-input name="web_air_tight_placement" type="checkbox" label="Dealer - Luchtdicht"></formulate-input>
            <formulate-input name="web_combination_windows_and_doors" type="checkbox" label="Dealer - Combinatie ramen en deuren"></formulate-input>
            <formulate-input name="web_exclusive_combination_windows_and_doors" type="checkbox" label="Dealer - Enkel in combinatie ramen en deuren"></formulate-input>
            <formulate-input name="web_show_on_locator" type="checkbox" label="Dealer - Tonen in locator"></formulate-input>
            <div class="formulate-input">
              <div class="formulate-input-label">
                {{ 'Dealer - Product categorieën' }}
              </div>
              <v-select multiple name="web_product_categories" :options="productCategoriesOptions" v-model="dealer.web_product_categories" :filterable="false" label="label"/>
            </div>
          </tab>
        </tabs>
      </formulate-form>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import { DateTime } from 'luxon'
import { Tabs, Tab } from 'vue-tabs-component'
import PageTitle from '@/components/admin/PageTitle'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    PageTitle,
    Tabs,
    Tab,
    vSelect
  },
  data: function () {
    return {
      isLoading: true,
      dealer: {},
      users: [],
      offset: 0,
      limit: 25,
      chosenUsers: [],
      hasPrevPage: false,
      totalUsers: 0,
      hasNextPage: true,
      search: null,
      dealerId: null,
      productCategoriesOptions: [
        {
          id: 717,
          label: 'Shutters'
        },
        { id: 719, label: 'Sunshade' },
        { id: 2122, label: 'Outdoor' },
        { id: 4101, label: 'Ventilation' }
      ],
      countries: {
        be: this.$t('België'),
        nl: this.$t('Nederland'),
        fr: this.$t('Frankrijk'),
        de: this.$t('Duitsland'),
        lu: this.$t('Luxemburg'),
        ua: this.$t('Oekraïne'),
        no: this.$t('Noorwegen'),
        it: this.$t('Italïe'),
        gb: this.$t('Verenigd Koninkrijk'),
        se: this.$t('Zweden')
      }
    }
  },
  async mounted () {
    this.isLoading = true
    this.dealerId = this.$route.params.id

    if (this.dealerId) {
      await this.fetchDealer()
    }
    this.isLoading = false
  },
  methods: {
    async prevUsers () {
      this.offset--
      this.hasPrevPage = this.offset > 0
      await this.fetchUsers()
    },
    async nextUsers () {
      this.offset++
      this.hasNextPage = this.totalUsers > this.limit
      await this.fetchUsers()
    },
    async searchUsers (search, loading) {
      this.offset = 0
      this.search = search
      await this.fetchUsers()
    },
    async fetchUsers () {
      const response = await ApiService.fetchUsers(this.offset, this.limit, this.search)
      this.users = response.data.users.map(user => {
        user.name = user.id + ' - ' + (!(user.profile.first_name === null || user.profile.first_name === '') ? (user.profile.first_name + ' ' + user.profile.last_name) : user.email)
        return user
      })
      this.totalUsers = response.data.total
    },
    fetchDealer: async function () {
      await ApiService.fetchDealer(this.dealerId).then((res) => {
        this.dealer = res.data

        if (this.dealer.loyalty) {
          for (const key in this.dealer.loyalty) {
            const value = this.dealer.loyalty[key]

            if (['seniority', 'review_end', 'review_start'].includes(key)) {
              if (value !== null) {
                this.dealer.loyalty[key] = DateTime.fromISO(value).toISODate()
              }
            }
          }

          this.dealer.loyalty = [this.dealer.loyalty]
        }

        if (this.dealer.web_product_categories) {
          this.dealer.web_product_categories = this.dealer.web_product_categories.map((categoryId) => this.productCategoriesOptions.find((obj) => obj.id === categoryId))
        }

        this.chosenUsers = this.dealer.users.map(user => {
          user.name = user.id + ' - ' + (!(user.profile.first_name === null || user.profile.first_name === '') ? (user.profile.first_name + ' ' + user.profile.last_name) : user.email)
          return user
        })

        this.dealer.address = [this.dealer.address]
      })

      await ApiService.fetchDealerLoyalty(this.dealerId).then(res => {
        if (res.status === 200) {
          this.$set(this.dealer, 'loyalty', [res.data])
        }
      })
    },
    saveDealer: async function (dealer) {
      if (Array.isArray(dealer.address)) {
        dealer.address = dealer.address[0]
      }

      if (dealer.web_product_categories) {
        dealer.web_product_categories = dealer.web_product_categories.map((dealer) => dealer.id)
      }

      dealer.users = this.chosenUsers.map(user => user.id)

      if (dealer.loyalty) {
        // if (dealer.loyalty.volume_revenue) {
        //   const volumeRevenue = []
        //   dealer.loyalty.volume_revenue.forEach((value, i) => {
        //     volumeRevenue.push(value)
        //   })
        //
        //   dealer.loyalty.volume_revenue = volumeRevenue
        // }

        // dealer.loyalty.seniority = dealer.loyalty.seniority ? DateTime.fromISO(dealer.loyalty.seniority) : null
        // dealer.loyalty.review_start = dealer.loyalty.review_start ? DateTime.fromISO(dealer.loyalty.review_start) : null
        // dealer.loyalty.review_end = dealer.loyalty.review_end ? DateTime.fromISO(dealer.loyalty.review_end) : null
      } else {
        dealer.loyalty = {}
      }

      if (Array.isArray(dealer.loyalty)) {
        dealer.loyalty = dealer.loyalty[0]

        if (dealer.loyalty.volume_revenue) {
          for (const rev of dealer.loyalty.volume_revenue) {
            rev.amount = parseFloat(rev.amount)
          }
        }
      }

      await ApiService.saveDealer(dealer)
        .catch((request) => {
          if (request.response.status !== 200) {
            request.response.data.detail.forEach((obj) => {
              notification(obj.msg, 'error')
            })
          }
        })
        .then(async (res) => {
          if (res.status === 200) {
            if (dealer.id) {
              notification('Dealer succesvol aangepast!')
              await ApiService.patchDealerLoyalty(dealer.id, dealer.loyalty)
            } else {
              notification('Dealer succesvol toegevoegd!')
            }
            await this.$router.push({ name: 'admin.dealers' })
          } else {
            if (Array.isArray(res.response.data.detail)) {
              res.response.data.detail.forEach((obj) => {
                notification(this.$t(obj.msg), 'error')
              })
            } else {
              notification(this.$t(res.response.data.detail), 'error')
            }
          }
        })
    }
  }
}
</script>
